<template>

  <div>

    <b-card header="筛选条件">
      <b-row>
        <!--调整编号-->
        <b-col md="4">
          <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="调整编号"
                  label-for="change_no"
                  label-size="sm"
                  class="mb-1"
          >
            <b-form-input
                    size="sm"
                    v-model="condition.change_no"
            />
          </b-form-group>
        </b-col>
        <!--结算编号-->
        <b-col md="4">
          <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="结算编号"
                  label-for="statement_no"
                  label-size="sm"
                  class="mb-1"
          >
            <b-form-input
                    size="sm"
                    v-model="condition.statement_no"
            />
          </b-form-group>
        </b-col>
        <!--关联编号-->
        <b-col md="4">
          <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="关联编号"
                  label-for="relational_no"
                  label-size="sm"
                  class="mb-1"
          >
            <b-form-input
                    size="sm"
                    v-model="condition.relational_no"
            />
          </b-form-group>
        </b-col>
        <!--状态-->
        <b-col md="4">
          <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="订单状态"
                  label-for="status"
                  label-size="sm"
                  class="mb-1"
          >
            <v-select
                    id="status"
                    :options="getCodeOptions('statement_change_status')"
                    v-model="condition.status"
                    name="orderStatus"
                    class="select-size-sm"
                    placeholder="请选择订单状态"
                    :reduce="option => option.value"
            />
          </b-form-group>
        </b-col>
        <!--结算单位-->
        <b-col
                cols="12"
                md="4"
        >
          <xy-input-button
                  label="结算单位"
                  type="input"
                  v-on:change="fromChildren($event,['store_name','store_id'])"
                  :params="['store_name','store_id']"
                  :value="condition.store_name"
                  modalName="团队"
                  placeholder="点击选择结算单位"
          >
          </xy-input-button>
        </b-col>
        <!--仓库名称-->
        <b-col
                cols="12"
                md="4"
                class="mb-1"
        >
          <xy-input-button
                  label="仓库名称"
                  type="input"
                  v-on:change="fromChildren($event,['warehouse_name','warehouse_id'])"
                  :params="['warehouse_name','warehouse_id']"
                  :value="condition.warehouse_name"
                  modalName="仓库"
                  placeholder="点击选择仓库"
          >
          </xy-input-button>
        </b-col>
        <!--创建人-->
        <b-col
                cols="12"
                md="4"
                class="mb-2"
        >
          <xy-input-button
                  label="创建人"
                  type="input"
                  v-on:change="fromChildren($event,['creatorName','creator'])"
                  :params="['creatorName','creator']"
                  :value="condition.creatorName"
                  modalName="创建人"
                  placeholder="点击选择创建人"
          >
          </xy-input-button>
        </b-col>

        <!--结算日期-->
        <b-col md="4" cols="12">
          <xy-input-button label="创建日期"
                           type="time"
                           v-on:change="fromChildren($event,['add_time_str'])"
                           :params="['add_time_str']"
                           :value="condition.add_time_str"
          >
          </xy-input-button>
        </b-col>


        <b-col md="4">

        </b-col>
        <b-col md="10">

        </b-col>

        <b-col
                cols="2"
        >
          <b-button
                  variant="primary"
                  class="mr-1"
                  @click="search()"
          >
            搜索
          </b-button>
          <b-button
                  variant="outline-secondary"
                  @click="reset()"
          >
            重置
          </b-button>
        </b-col>
      </b-row>


    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #row-details="row">
          <!--<b-card v-for="i in row.item.ext.items" :key="i.name">-->
          <b-card>
            <div>
              <b-table striped hover :items="row.item.ext.items"  :fields="fields">

              </b-table>
            </div>
          </b-card>
        </template>
        <!-- Columns -->
      <!--  <template #cell(id)="data">
          <b-link
              :to="{ name: 'apps-statementchange-edit', query: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
          >
            #{{ data.item.id }}
          </b-link>
        </template>
-->
        <!-- Column: Type -->
        <template #cell(creator)="data">
          {{getCodeLabel('user', data.item.creator)}}
        </template>

        <template #cell(updator)="data">
          {{getCodeLabel('user', data.item.updator)}}
        </template>

        <template #cell(status)="data">
            {{getCodeLabel("statement_change_status",data.item.status)}}
        </template>

        <template #cell(modify_time)="data">
          {{toDate(data.item.modify_time)}}
        </template>

        <template #cell(add_time)="data">
          {{toDate(data.item.add_time)}}
        </template>

        <template #cell(after_total_amount)="data">
          <div v-if="data.item.total_amount===undefined">
            {{data.item.amount}}
          </div>
          <div v-if="data.item.amount===undefined">
            {{data.item.total_amount}}
          </div>
         <div v-if="data.item.total_amount!=undefined&&data.item.amount!=undefined">
            {{data.item.amount+data.item.total_amount}}
         </div>
        </template>

        <template #cell(remark)="data">
          <div :id="`remark-row-${data.item.id}`">
            {{ data.value.substring(0, 4) }}
            <span v-if="data.value.length > 4"> ...</span>
          </div>
          <b-tooltip
                  :target="`remark-row-${data.item.id}`"
                  placement="top"
                  v-if="data.value"
          >
            {{ data.value }}
          </b-tooltip>
        </template>

        <template #cell(attachments)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :attachment_id="'attachments'"
                             :id="data.item.attachments"
                             :object_type="'statement_change'"
                             :object_id="0"
                             :readonly="true"

          />
        </template>



        <template #cell(change_no)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                    icon="MinusSquareIcon"
                    size="15"
                    class="align-middle text-body"
                    v-show="data.detailsShowing==true"
            />
            <feather-icon
                    icon="PlusSquareIcon"
                    size="16"
                    class="align-middle text-body"
                    v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          [#{{ data.item.id }}]{{ data.item.change_no }}
        </template>


        <template #cell(statement_no)="data">
          <b-link
                  :to="{ name: 'apps-statement-list', query: { id: data.item.statement_id } }"
                  target="_blank"
          >
            {{ data.item.statement_no }}
          </b-link>

        </template>



        <template #cell(relational_no)="data">
          <b-link
                  :to="{ name: 'apps-offlinesalesorder-edit', query: { id: data.item.relational_id },params:{readonly:1} }"
                  target="_blank"
          >
            {{ data.item.relational_no }}
          </b-link>

        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
            @click="changeState(data.item)"
          >
          <b-badge
            pill
            :variant="`light-$ {getCodeColor('state', data.item.state)}`"

          >
            {{getCodeLabel('state', data.item.state)}}
          </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>

<!--多菜单-->
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item v-if="data.item.status===1">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50" @click="submit(data.item.id,2)">提交</span>
            </b-dropdown-item>

            <div v-if="data.item.status===2">
              <b-dropdown-item>
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50" @click="submit(data.item.id,3)">审核通过</span>
              </b-dropdown-item>

              <b-dropdown-item>
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50" @click="submit(data.item.id,4)">审核失败</span>
              </b-dropdown-item>
            </div>

            <b-dropdown-item  v-if="data.item.status===1">
              <feather-icon icon="DeleteIcon" />
              <span class="align-middle ml-50" @click="deleteMethod(data.item.id)">删除</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, reactive, onMounted, toRefs} from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import statementchangeUseList from './statementchangeUseList'
import statementchangeStore from './statementchangeStore'
import {useToast} from "vue-toastification/composition";
import XyInputButton from "@/views/components/xy/XyInputButton";
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import {useRouter} from "@core/utils/utils";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    XyInputButton,
    AttachmentUpload
  },
  methods: {
    changeState: function(data) {
      const state = 1 - data.state
      store.dispatch('statementchange/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
  },
  data(){
    return{
      fields: [
        { key: 'product_id', label: '商品ID' },
        { key: 'code', label: '商品编码(69码)'},
        { key: 'name', label: '名称' },
        { key: 'specification', label: '商品规格'},
        { key: 'qty', label: '销售数量' },
        { key: 'refuse_qty', label: '拒收数量' },
        { key: 'product_cost_tax', label: '单价' },
        { key: 'product_cost_tax_change', label: '调整单价' },
        { key: 'remark', label: '备注'},
      ],
    }
  },
  setup() {

    const toast = useToast()
    const router = useRouter()
    // Register module
    if (!store.hasModule('statementchange')) store.registerModule('statementchange', statementchangeStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('statementchange')) store.unregisterModule('statementchange')
    })

    onMounted(() => {
      state.condition = store.getters['statementchange/getCondition']
    })

    const state = reactive({
      condition:{
      }
    })

    const search = function () {
        this.refetchData()
    }
    const reset = function () {
      store.commit('statementchange/resetCondition', state)
      refetchData()
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        state.condition[modal[i]] =params==null?null:params[modal[i]]
      }
      this.$forceUpdate()
    }

    const deleteMethod = function (id) {
      store.dispatch('statementchange/state', {id: id, state: 0}).then(res => {
          toast.success("删除成功")
          refetchData()
      })
    }

    const submit = function (id,status) {
      store.dispatch('statementchange/submit', {id: id, status: status}).then(res => {
        toast.success("操作成功")
        refetchData()
      })
    }



    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = statementchangeUseList(
            {
              id:router.route.value.query.id
            }
    )

    return {
      ...toRefs(state),
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
     toTime, 
     toDate,
      search,reset,fromChildren,deleteMethod,submit
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
